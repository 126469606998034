@import 'src/assets/scss/customVariables';

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  width: 45vw;

  .successMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    text-align: center;
    max-width: 290px;
    width: 100%;

    .title,
    .subtitle {
      color: $black_0;
      width: 40vw;
    }
  }
}
