@import 'src/assets/scss/customVariables';

.mainContainer {
  overflow: auto;

  .controlButton {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: $spacer3;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    color: $black_0;
    font-weight: 400;
    font-size: 1rem;
  }

  .active {
    background-color: $black_500;
    color: $black_0;
    font-weight: 700;
  }
}
