@import 'src/assets/scss/customVariables';

.small {
  font-weight: 600;
  font-size: $text_base;
  line-height: $text_l;
}

.medium {
  font-weight: 600;
  font-size: $text_l;
  line-height: $text_xxl;
}

.large {
  font-weight: 700;
  font-size: $text_xxl;
  line-height: 44px;
}

.extraLarge {
  font-weight: 900;
  font-size: $text_xxxl;
  line-height: 76px;
}

@media only screen and (max-width: 600px) {
  .small {
    font-size: $text_tiny;
    line-height: $text_base;
  }

  .medium {
    font-size: $text_s;
    line-height: $text_lg;
  }

  .large {
    font-weight: 600;
    font-size: $text_base;
    line-height: $text_l;
  }

  .extraLarge {
    font-weight: 600;
    font-size: $text_l;
    line-height: $text_xxl;
  }
}
