@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;

    .form {
      width: 100%;

      &Item {
        display: flex;
        margin-bottom: $spacer4;

        &Label {
          padding-top: 0.7rem;
          min-width: 9rem;
          font-size: $text_tiny;
          font-weight: 700;
        }

        &RadioGroup {
          display: flex;
          margin-top: 0.7rem;

          label {
            margin-right: $spacer4;
          }
        }

        .ugc {
          padding-top: 0.7rem;
        }

        .datePicker {
          width: 100%;
          border: 1px solid $black_100;
          border-radius: 4px;
          height: $inputHeight;
          -webkit-appearance: none;
          padding: 12px;

          &:hover {
            border: 1px solid $main_purple_500;
          }

          &:focus {
            border: 1px solid $main_purple_500;
            box-shadow: 1px 1px 0px $green_300;
            outline: none;
          }
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $spacer7;

        .mainButtons {
          display: flex;
          align-items: center;

          .button {
            margin-left: $spacer;
          }
        }
      }
    }
  }
}
