@import 'src/assets/scss/customVariables';

.wrapper {
  height: $inputHeight;
  width: 100%;
  -webkit-appearance: none;

  .datepickerWrapper {
    position: relative;
    padding: 0.625rem 0.875rem;
    height: inherit;
    width: inherit;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background: $black_500;

    &::placeholder {
      color: $black_60;
    }

    &:hover {
      border: 1px solid $black_40;
    }

    &:active,
    &:focus {
      box-shadow: 0px 0px 0px 4px #adadad, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .icon {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      cursor: pointer;
      background-color: $black_500;

      * {
        stroke: $black_0;
      }
    }

    .calendarWrapper {
      position: absolute;
      z-index: 5000;
      top: 2.3rem;
      right: 0;

      :global(.rdrCalendarWrapper) {
        background-color: $black_500;
      }

      :global(.rdrDayNumber span) {
        color: $black_0;
      }

      :global(.rdrSelected) {
        background-color: $black_700 !important;
        border: 1px solid $green_yellow_500;
      }

      :global(.rdrNextPrevButton) {
        background-color: $green_yellow_500 !important;
      }

      :global(.rdrNextPrevButton:hover) {
        background-color: $green_yellow_600 !important;
      }

      :global(.rdrMonthAndYearPickers select) {
        color: #ffffff !important;
        background-color: $black_500;
      }
    }

    .maskInput {
      input {
        border: none;
        height: $inputHeight;
        width: calc(100% - 2.75rem);
        -webkit-appearance: none;
        font-weight: 400;
        font-size: $text_tiny;
        position: absolute;
        left: 10px;
        top: 0;
        z-index: 10;
        background: none;
        outline: none;
        color: $black_0;
      }
    }

    .invalid {
      input {
        outline-color: rgba($red_300, 0.5) !important;
        border: 1px solid rgba($red_300, 0.5) !important;
      }
    }
  }

  .errors {
    margin-top: 0.25rem;

    .errorItem {
      height: 1.125rem;
      display: flex;
      align-items: center;
      color: $red_300;
      font-size: 0.75rem;

      &Icon {
        padding-right: 0.5rem;
      }
    }
  }
}
