.dropDownWrapper {
  position: relative;

  .dropDownMenu {
    z-index: 1;
    position: absolute;
    top: 30px;
    width: 100%;
    min-width: max-content;
  }
}
