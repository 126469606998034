@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
  height: 100vh;
}

.Toastify > div > div {
  padding: 0px !important;
  border-radius: 0px !important;
  background-color: #262626;
}

.Toastify > div > div button {
  padding: 9px 9px 0px 0px !important;
  color: #ffffff;
}

.Toastify > div > div .Toastify__progress-bar {
  visibility: hidden !important;
}

.Toastify > div > div > div {
  padding: 0px !important;
}

img {
  max-width: 100%;
}

/* Vertical Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  background-color: #262626;
}

/* Horizontal Scrollbar */
::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #262626;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
  border-radius: 5px;
  background-color: #262626;
}

/* Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
  background-color: rgba(0, 0, 0, 0.12);
}
