@import 'src/assets/scss/customVariables';

.container {
  position: relative;
  width: 100%;
}

.searchField {
  border: 1px solid $black_100;
  -webkit-appearance: none;
  padding: 12px 48px;
  font-size: $text_tiny;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  &:hover {
    border: 1px solid $main_purple_500;
  }

  &:focus {
    border: 1px solid $main_purple_500;
    box-shadow: 1px 1px 0px $green_300;
    outline: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.menu {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
