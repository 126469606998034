@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;
    display: flex;
    align-self: center;
    justify-content: center;
    height: 32rem;
    gap: 2rem;

    .card {
      display: flex;
      width: 19.875rem;
      height: 10.625rem;
      padding: 1.5rem 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 1rem;
      flex-shrink: 0;
      background: $black_500;
      border-radius: 0.5rem;
      border: 1px solid #5e5e5e;

      &:hover {
        border: 1px solid $black_60;
        cursor: pointer;
      }

      .title {
        text-align: center;
        color: $black_0;
        font-weight: 600;
        font-size: $text_base;
      }

      .desc {
        text-align: center;
        color: $black_50;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: $spacer;

    .button {
      margin-left: $spacer;
    }
  }
}
