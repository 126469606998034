@import 'src/assets/scss/customVariables';

.container {
  padding: 8px;
  background-color: $green_500;
  font-size: $text_tiny;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .iconContainer {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    display: flex;
  }
}

.containerLeft {
  flex-direction: row-reverse;
  .iconContainer {
    margin-left: 0px;
    margin-right: 10px;
  }
}
