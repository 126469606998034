@import 'src/assets/scss/customVariables';

.content {
  background: $black_700;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1.25rem;
  width: 100%;
  border: 1px solid $black_500;

  .linkContainer {
    display: flex;
    column-gap: 12px;

    &Text {
      font-size: $text_tiny;
      color: $black_0;
    }
  }

  .bottomLine {
    width: 50%;
    border: 1px solid $black_40;
  }

  .bottomLink {
    text-decoration: none;
    color: $green_yellow_500;
  }
}
