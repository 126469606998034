@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;

    .msg {
      font-size: $text_sm;
    }

    .project {
      font-size: $text_tiny;
      font-weight: 700;
      margin-top: $spacer;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: $spacer5;

      .button {
        margin-left: $spacer;
      }
    }
  }
}
