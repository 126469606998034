@import 'src/assets/scss/customVariables';

.container {
  position: relative;
  height: $inputHeight;
  cursor: pointer;

  .dropDownWrapper {
    background-color: $black_0;
    padding: 0.625rem 0.875rem;
    font-size: $text_tiny;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    border-radius: 0.5rem;
    box-sizing: border-box;

    &:hover {
      border: 1px solid $black_40;
    }

    &:active,
    &:focus {
      box-shadow: 0px 0px 0px 4px #adadad, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    p {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .placeholder {
      opacity: 0.6;
    }
  }

  .darkMode {
    background-color: $black_500;
    border: 1px solid $black_500;
    color: $black_0;

    &:active,
    &:focus {
      box-shadow: 0px 0px 0px 4px #b6b6b6, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .placeholder {
      opacity: 1;
    }
  }

  .iconContainer {
    top: 15px;
    right: 13px;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconDarkMode {
    svg {
      path {
        fill: $black_0;
      }
    }
  }

  .menu {
    position: absolute;
    background-color: $black_0;
    width: 100%;
    z-index: 11;
    max-height: 25rem;
    overflow-x: hidden;
    border-radius: 0.5rem;
  }

  .menuDarkMode {
    background-color: $black_700;
  }
}
