@import 'src/assets/scss/customVariables';

.placement {
  display: flex;
  width: 6.25rem;
  padding: 1rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.25rem;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  border: 1px solid $violet_100;

  .item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
