@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;

    .main {
      display: flex;
      height: 30rem;

      .left {
        width: 25%;
        border-right: 1px solid $black_60;
        overflow-y: auto;

        .section {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: $spacer;
          padding-right: $spacer2;

          &Header {
            font-weight: 700;
            color: $black_0;
            margin-bottom: $spacer2;
          }

          &Item {
            width: 100%;

            &:hover {
              cursor: pointer;
              background-color: $black_500;
            }

            &:not(&:last-child) {
              margin-bottom: $spacer2;
            }
          }

          &ItemActive {
            width: 100%;
            background-color: $black_500;

            &:hover {
              cursor: initial;
            }

            &:not(&:last-child) {
              margin-bottom: $spacer2;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .right {
        width: 75%;
        padding-left: $spacer;
        overflow-y: auto;

        .item {
          margin-bottom: $spacer5;
          display: flex;

          &Input {
            width: 100%;
            margin-right: auto;
            font-size: $text_tiny;

            .title {
              max-width: 48rem;
            }

            .author {
              display: flex;
              align-items: center;

              > :first-child {
                margin-right: $spacer;
              }
            }

            .dropdown,
            .numberInput,
            .datePicker {
              width: 20rem;

              .dropdownInputWrapper {
                max-height: 20rem;
              }
            }

            .checkbox {
              justify-content: flex-start;
              width: min-content;
              margin-bottom: $spacer;
              white-space: nowrap;
            }

            .richEditor {
              height: 17rem;
              max-width: 48rem;
              border: 1px solid $black_100;
              border-radius: 8px;
              background-color: $black_500;
              color: $main_black_900;

              &Toolbar {
                border: none;
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
                margin: 0;
                background-color: $black_700;

                a {
                  color: $main_black_900;
                }

                div > div {
                  border: none;
                  background-color: $black_100;
                }
              }

              &Editor {
                max-height: 8rem;
                margin: $spacer2;
                color: $black_0;
              }

              &:hover {
                border: 1px solid $black_40;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              }
            }

            .textarea {
              width: 48rem;
            }

            .other {
              display: flex;
              align-items: center;

              &Input {
                margin-left: $spacer4;
                max-width: 20rem;
              }
            }

            .assetVariations {
              max-height: 26rem;
              max-width: 30rem;
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;

              &Item {
                justify-content: flex-start;
                margin-right: $spacer;
                margin-bottom: $spacer;
              }
            }

            .visualFeatures {
              display: flex;
              flex-wrap: wrap;

              > div {
                margin-right: $spacer5;
                margin-bottom: $spacer5;
              }
            }
          }
        }

        .methodologySection {
          font-size: $text_tiny;
          font-weight: 700;
          margin-bottom: $spacer;
        }

        .ratingItem {
          margin-bottom: $spacer;
          display: flex;

          .textarea {
            margin-left: 3rem;
            width: 36rem;
          }
        }

        .ctaPlacementItem {
          margin-bottom: $spacer;
          display: flex;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: $spacer;

      > :first-child {
        margin-right: $spacer;
      }
    }
  }
}
