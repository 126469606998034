@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;

    .sections {
      display: flex;
      flex-wrap: wrap;

      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 33%;
        margin-bottom: $spacer;

        &Header {
          font-weight: 700;
          color: $black_0;
          margin-bottom: $spacer;
        }

        &Item {
          margin-bottom: $spacer2;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: $spacer;

      > :first-child {
        margin-right: $spacer;
      }
    }
  }
}
