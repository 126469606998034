$black_0: #ffffff;
$black_10: #fafafa;
$black_20: #f5f5f5;
$black_30: #ebebeb;
$black_40: #dfdfdf;
$black_50: #c2c2c2;
$black_60: #b3b3b3;
$black_70: #373737;
$black_80: #999999;
$black_90: #8a8a8a;
$black_100: #7b7b7b;
$black_400: #3f3e3e;
$black_500: #434343;
$black_600: #1d1d49;
$black_700: #262626;
$black_800: #171717;
$main_black_900: #1e1e1e;

$green_yellow_25: #fcfff8;
$green_yellow_50: #fafff0;
$green_yellow_100: #eefed1;
$green_yellow_200: #e6feba;
$green_yellow_300: #dafe9b;
$green_yellow_400: #d3fd88;
$green_yellow_500: #c8fd6a;
$green_yellow_600: #b6e660;
$green_yellow_700: #8eb44b;
$green_yellow_800: #6e8b3a;
$green_yellow_900: #546a2d;

$violet_25: #f5f4fa;
$violet_50: #ece9f7;
$violet_100: #c5bce6;
$violet_200: #a99bd9;
$violet_300: #826ec8;
$violet_400: #6a51bd;
$violet_500: #4526ad;
$violet_600: #3f239d;
$violet_700: #311b7b;
$violet_800: #26155f;
$violet_900: #1d1049;

$purple_50: #ece9f7;
$purple_100: #c5bce6;
$purple_300: #826ec8;
$main_purple_500: #4526ad;

$green_50: #e6fff2;
$green_100: #b0ffd7;
$green_300: #54ffa9;
$green_500: #00ff7f;

$red_300: #e6726a;

$pink_50: #fee8f3;
$pink_100: #fdb7db;
$pink_300: #fa64b1;
$pink_500: #f7188a;

$fuchsia_50: #ffe6ff;
$fuchsia_100: #ffb0ff;
$fuchsia_300: #ff54ff;
$fuchsia_500: #ff00ff;

$blue_50: #e6ffff;
$blue_100: #b0ffff;
$blue_300: #54ffff;
$blue_500: #00ffff;

// Spacing

$spacer: 1rem;

// for easier use in the app
$spacer0: 0;
$spacer1: $spacer * 0.25;
$spacer2: $spacer * 0.5;
$spacer3: $spacer;
$spacer4: $spacer * 1.5;
$spacer5: $spacer * 2;
$spacer6: $spacer * 2.5;
$spacer7: $spacer * 3;

// Body

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$bp_xs: 0;
$bp_sm: 576px;
$bp_md: 768px;
$bp_lg: 992px;
$bp_xl: 1200px;
$bp_xxl: 1400px;
$bp_xxxl: 1600px;

$grid-breakpoints: (
  xs: $bp_xs,
  sm: $bp_sm,
  md: $bp_md,
  lg: $bp_lg,
  xl: $bp_xl,
  xxl: $bp_xxl,
  xxxl: $bp_xxxl,
);

$max_width_sm: 540px;
$max_width_md: 720px;
$max_width_lg: 960px;
$max_width_xl: 1140px;
$max_width_xxl: 1140px;
$max_width_xxxl: 1140px;

$container-max-widths: (
  sm: $max_width_sm,
  md: $max_width_md,
  lg: $max_width_lg,
  xl: $max_width_xl,
  xxl: $max_width_xxl,
  xxxl: $max_width_xxxl,
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$text_tiny: 1rem;
$text_min: $text_tiny * 0.5; // 8px
$text_xs: $text_tiny * 0.75; // 12px
$text_sm: $text_tiny * 0.875; // 14px
$text_s: $text_tiny * 1.125; // 18px
$text_base: $text_tiny * 1.25; // 20px
$text_lg: $text_tiny * 1.5; // 24px
$text_l: $text_tiny * 1.75; // 28px
$text_x: $text_tiny * 2.5; // 40px
$text_xl: $text_tiny * 2; // 32px todo why xl is smaller than x
$text_xxl: $text_tiny * 2.25; // 36px todo why xxl is smaller than x
$text_xxxl: $text_tiny * 3; // 48px

.modalHeader {
  .title {
    font-size: $text_base;
    font-weight: 600;
    color: $black_0;
  }
}

$inputHeight: 2.75rem;

.link {
  color: $green_yellow_500;

  &:hover {
    color: $green_yellow_700;
    text-decoration: underline;
    cursor: pointer;
  }
}
