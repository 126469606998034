@import 'src/assets/scss/customVariables';

.small {
  font-weight: 400;
  font-size: $text_sm;
  line-height: $text_base;
}

.medium {
  font-weight: 400;
  font-size: $text_tiny;
  line-height: $text_lg;
}

.large {
  font-weight: 400;
  font-size: $text_s;
  line-height: $text_l;
}

@media only screen and (max-width: 600px) {
  .small {
    font-size: $text_xs;
    line-height: $text_tiny;
  }

  .medium {
    font-size: $text_sm;
    line-height: $text_base;
  }

  .large {
    font-size: $text_tiny;
    line-height: $text_lg;
  }
}
