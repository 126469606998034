@import 'src/assets/scss/customVariables';

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    margin-right: 12px;
  }
}

.toggleContainerRight {
  flex-direction: row-reverse;
  p {
    margin-right: 0px;
    margin-left: 12px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  border: 1px solid $main_black_900;
  border-radius: 100px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  &:hover {
    border: 1px solid $main_purple_500;
    .slider:before {
      background-color: $main_purple_500;
    }
    input:checked + .slider:before {
      background-color: $black_0;
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 3px;
    top: 3px;
    background-color: $main_black_900;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}

input:checked + .slider {
  background-color: $main_black_900;
  &:hover {
    background-color: $main_purple_500;
  }
  &:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
    background-color: $black_0;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
