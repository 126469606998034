@import 'src/assets/scss/customVariables';

.container {
  padding: $spacer0;
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    .invitationEmail {
      width: 100%;
      color: $black_0;

      .userEmailContainer {
        display: flex;
        justify-content: space-between;

        h1 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        .emailCheck {
          text-decoration: underline;
        }
      }
    }

    .formButton {
      width: 100%;
    }

    .agreement {
      max-width: 340px;
      display: flex;
      color: #b3b3b3;

      .agreementStatement {
        display: flex;
        flex-wrap: wrap;
        column-gap: 3px;
      }

      .terms {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: $fuchsia_500;
        }
      }
    }

    .errorList {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      row-gap: 4px;

      .passwordRequirements {
        display: flex;
        align-items: center;
        column-gap: 6px;
        color: $black_0;

        .icon {
          width: 16px;
          height: 16px;

          * {
            fill: #7b7b7b;
          }
        }
      }
    }

    .inputLabelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .inputLabel {
        margin-bottom: 8px;
        font-size: $text_sm;
        display: flex;
        column-gap: 5px;
        color: $black_0;

        p {
          color: $black_60;
        }
      }

      .inputLink {
        text-decoration: underline;
      }
    }

    .expiredSession {
      display: flex;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      padding-right: $spacer2;
      padding-left: $spacer2;
      color: $black_0;
    }

    .title {
      margin-bottom: $spacer3;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}

@media (max-width: $bp_sm) {
  .container {
    .content {
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
  }
}
