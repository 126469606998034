@import 'src/assets/scss/customVariables';

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  .formButton {
    width: 100%;
  }

  .successMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    text-align: center;
    max-width: 290px;
    color: $black_0;
  }

  .inputLabelContainer {
    margin: 0 0 8px;
    color: $black_0;
  }
}
