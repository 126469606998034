@import 'src/assets/scss/customVariables';

.link {
  font-weight: 400;
  line-height: 20px;
  color: $violet_500;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: $violet_700;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  color: $black_50;
}

.smallLink {
  font-size: $text_sm;
}

.defaultLink {
  font-size: $text_sm;
}

.withIcon {
  color: $red_300;
}
