@import 'src/assets/scss/customVariables';

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    margin-right: 12px;
  }
}

.checkboxContainerDarkMode {
  p {
    color: $black_0;
  }
}

.checkboxContainerRight {
  flex-direction: row-reverse;

  p {
    margin-right: 0px;
    margin-left: 0.5rem;
  }
}

.labelAfter {
  p {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}

.container {
  display: flex;
  position: relative;
  height: 1.1rem;
  width: 1.1rem;
  z-index: 1;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover {
    border: none;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 0.25rem;
  border: 1px solid $black_0;
  background: $black_500;

  &:hover {
    border-radius: 0.25rem;
    border: 1px solid $black_50 !important;
    background-color: $black_500;
  }

  &:active,
  &:focus {
    border-radius: 0.25rem;
    border: 1px solid $black_700;
    background: $black_500;
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    border: solid white !important;
    border-width: 0 2px 2px 0 !important;
  }
}

.checkmarkDarkMode {
  border: 1px solid $black_30;
  background: transparent;

  &:hover {
    border: 1px solid $black_700;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:active,
  &:focus {
    border: 1px solid $black_30;
    box-shadow: none;
    outline: none;
  }
}

.container:hover input ~ .checkmark {
  &:hover {
    border: 1px solid $violet_500;
  }
}

.container input:checked ~ .checkmark {
  &:hover {
    border: 1px solid $violet_500;
  }
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid $violet_500;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.containerDarkMode:hover input ~ .checkmark {
  &:hover {
    border: 1px solid $black_80;
    background-color: transparent;
  }
}

.containerDarkMode input:checked ~ .checkmark {
  &:hover {
    border: 1px solid $black_80;
    background-color: transparent;
  }
}

.containerDarkMode .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid $black_0;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
