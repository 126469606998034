@import 'src/assets/scss/customVariables';

.container {
  padding: $spacer0;
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    .formButton {
      width: 100%;
    }

    .errorList {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      row-gap: 4px;

      .passwordRequirements {
        display: flex;
        align-items: center;
        column-gap: 6px;
        color: $black_0;

        .icon {
          width: 16px;
          height: 16px;

          * {
            fill: #7b7b7b;
          }
        }
      }
    }

    .inputLabelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .inputLabel {
        margin-bottom: 8px;
        font-size: $text_sm;
        display: flex;
        column-gap: 5px;
        color: $black_0;

        p {
          color: $black_60;
        }
      }
    }
  }
}
