@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;
    position: relative;

    .loadingWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      z-index: 5000;
    }

    .form {
      width: 100%;

      &Item {
        display: flex;
        margin-bottom: $spacer;

        &Label {
          width: 12rem;
          font-size: $text_tiny;
          font-weight: 700;
        }
      }

      .dropzone {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: $spacer;
        padding: $spacer3;
        border: 1px dashed $black_60;
        border-radius: 10px;
        background: $black_10;
        outline: none;
        transition: border 0.24s ease-in-out;
        font-size: 0.875rem;
        height: 29rem;
        width: 100%;

        &WithMedias {
          @extend .dropzone;
          width: 13rem;
        }

        .upload {
          font-size: $text_tiny;
          margin-bottom: $spacer2;
        }

        .dragAndDrop {
          font-size: $text_sm;
          display: flex;

          .browse {
            margin-left: $spacer1;
            min-height: 0.5rem;
            font-size: $text_sm;
          }

          .text1 {
            color: $black_100;
          }
        }

        .text2 {
          color: $black_100;
        }
      }

      .maxFiles {
        margin-bottom: $spacer;
      }

      .mainSection {
        display: flex;
        height: 32rem;

        .mediasWrapper {
          margin-left: 1.5rem;
          max-height: 31rem;
          overflow-y: auto;
          display: flex;

          $marginBottom: $spacer;

          .slides {
            width: 4.5rem;
            margin-right: 1rem;

            .slide {
              font-size: $text_tiny;
              height: 11rem;
              display: flex;
              align-items: center;
              margin-top: 1rem;
            }

            .separator {
              margin-bottom: $marginBottom;
            }
          }

          .medias {
            width: 100%;
            height: 100%;

            .media {
              height: 9rem;
              border-radius: 8px;
              border: 1px solid $black_40;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: inherit;
              padding: $spacer;

              .drag {
                height: 100%;
                min-width: 3rem;
                background: $black_500;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
              }

              .image {
                width: 7rem;
                height: 7rem;

                video {
                  width: inherit;
                  height: inherit;
                }
              }

              .details {
                padding: $spacer 0 $spacer $spacer;
                width: 40rem;

                .actions {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: $spacer;

                  .size {
                    color: $black_100;
                    font-size: $text_sm;
                  }

                  .delete {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                      width: 1rem;
                      height: 1rem;
                      opacity: 0.5;

                      path {
                        fill: $black_0;
                      }

                      &:hover {
                        opacity: 1;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }

              .detailsWithDrag {
                padding-left: 1rem;
                width: 32rem;
              }
            }

            .dropSection {
              height: 1rem;
            }

            .separator {
              margin-bottom: $marginBottom;
            }
          }
        }

        .multiSizeMediasWrapper {
          max-height: 31rem;
          width: 49rem;
          margin-left: $spacer4;
          overflow-y: auto;
          display: flex;

          .medias {
            width: 100%;
            height: 100%;
            gap: 1.5rem 2rem;
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            flex-shrink: 0;

            .media {
              border-radius: 8px;
              border: 1px solid $black_40;
              padding: $spacer;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              gap: 0.75rem;

              &Header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                .sizeSelector {
                  //cursor: pointer;

                  > span {
                    margin-right: 0.5rem;
                  }
                }

                .delete {
                  width: 1rem;
                  height: 1rem;
                  cursor: pointer;
                }
              }

              // do not remove this inheritance, otherwise ImageKit transformation doesn't work, actual width is set via style
              .video {
                video {
                  width: inherit;
                  height: inherit;
                }
              }

              &Content {
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: $spacer;

        .button {
          margin-left: $spacer;
        }
      }
    }
  }
}

.formItem {
  &__ad_type {
    display: flex;
    column-gap: 1rem;
    width: 100%;
  }
}
