@import 'src/assets/scss/customVariables';

.container {
  padding: $spacer0;
  display: flex;
  justify-content: center;
  width: 100%;

  .contentWrapper {
    width: 100%;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 20px;
      width: 100%;
    }

    .formButton {
      width: 100%;
      font-weight: 24px;
    }

    .inputLabelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 8px;
      color: $black_0;

      .inputLink {
        font-size: $text_sm;
        color: $black_0;
      }
    }

    .expiredSession {
      display: flex;
      justify-content: center;
      font-weight: bold;
      align-items: center;
      padding-right: $spacer2;
      padding-left: $spacer2;
      color: $black_0;
    }

    .title {
      margin-bottom: $spacer3;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}

@media (max-width: $bp_sm) {
  .container {
    .contentWrapper {
      .content {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }
  }
}
