@import 'src/assets/scss/customVariables';

.mainContainer {
  display: flex;
  column-gap: $spacer4;

  .tabTitles {
    flex: 1;
  }

  .content {
    flex: 6;
    background-color: $black_700;
    padding: 32px 24px;
    border-radius: 0.5rem;
  }
}
