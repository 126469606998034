@import 'src/assets/scss/customVariables';

.radioContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: $text_tiny;

  p {
    margin-right: 12px;
  }
}

.radioContainerDarkMode {
  p {
    color: $black_0;
  }
}

.radioContainerRight {
  flex-direction: row-reverse;

  p {
    margin-right: 0px;
    margin-left: 12px;
  }
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
  width: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid $violet_100;

  &:hover {
    border: 1px solid $violet_500;

    &:after {
      background: $violet_500;
    }
  }

  &:after {
    content: '';
    display: none;
    background: $violet_500;
    width: 8px;
    height: 8px;
    margin: auto;
    border-radius: 50%;
  }
}

.checkmarkDarkMode {
  border: 1px solid $black_30;
  background: transparent;

  &:hover {
    border: 1px solid $violet_100;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:active,
  &:focus {
    border: 1px solid $black_30;
    box-shadow: none;
    outline: none;
  }
}

.container input:checked ~ .checkmark:after {
  display: block;

  &:hover {
    background: $violet_500;
  }
}

.containerDarkMode input:checked ~ .checkmark:after {
  display: block;
  background: $black_0;

  &:hover {
    background: $black_0;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
