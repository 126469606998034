@import 'src/assets/scss/customVariables';

.container {
  overflow-y: auto;
  width: 100%;
  //z-index: 1;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  background-color: $black_0;
  color: $main_black_900;
  border-radius: 0.5rem;
  border: 1px solid #f2f4f7;
  max-height: 20rem;

  .chosen {
    background-color: #5e5e5e;
  }

  .checkbox {
    pointer-events: none;
    margin-left: 16px;
    width: 95%;

    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .field {
    width: 100%;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: $text_sm;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    // solution for an item with tooltip
    > span {
      width: inherit;

      > label {
        justify-content: flex-end;
      }
    }

    .value {
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      width: inherit;

      .icon {
        margin-right: $spacer2;
        height: 1.3rem;
        width: 1rem;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }

      .valueContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .chosenIcon {
          margin-left: 1rem;
        }
      }
    }

    &:hover {
      background-color: $violet_25;

      .value {
        .icon {
          svg {
            path {
              fill: $black_0;
            }
          }
        }
      }
    }
  }
}

.containerDarkMode {
  background-color: $black_500;
  color: $black_0;
  border: none;

  .field {
    &:hover {
      background-color: #5e5e5e;

      .value {
        .icon {
          svg {
            path {
              // fill: $black_700;
            }
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: $black_60;
  border-radius: 10px;
}
