// Include any default variable overrides here
@import 'customVariables';

// custom CSS goes below

html:root {
  --black_0: $black_0;
  --black_10: $black_10;
  --black_20: $black_20;
  --black_40: $black_40;
  --black_60: $black_60;
  --black_100: $black_100;
  --black_500: $black_500;
  --main_black_900: $main_black_900;
  --purple_50: $purple_50;
  --purple_100: $purple_100;
  --purple_300: $purple_300;
  --main_purple_500: $main_purple_500;
  --green_50: $green_50;
  --green_100: $green_100;
  --green_300: $green_300;
  --green_500: $green_500;
  --pink_50: $pink_50;
  --pink_100: $pink_100;
  --pink_300: $pink_300;
  --pink_500: $pink_500;
  --fuchsia_50: $fuchsia_50;
  --fuchsia_100: $fuchsia_100;
  --fuchsia_300: $fuchsia_300;
  --fuchsia_500: $fuchsia_500;
  --blue_50: $blue_50;
  --blue_100: $blue_100;
  --blue_300: $blue_300;
  --blue_500: $blue_500;
}

html:root {
  --text_min: $text_min;
  --text_xs: $text_xs;
  --text_sm: $text_sm;
  --text_tiny: $text_tiny;
  --text_base: $text_base;
  --text_lg: $text_lg;
  --text_x: $text_x;
  --text_xl: $text_xl;
  --text_l: $text_l;
}

body {
  * {
    margin: 0;
  }
}
