@import 'src/assets/scss/customVariables';

.button {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
  white-space: nowrap;
  font-size: $text_sm;
  font-weight: 500;
  color: $main_black_900;
}

.buttonSmallSize {
  min-height: 36px;
  font-size: $text_xs;
  line-height: 1.25rem;
  padding: 0.5rem 0.875rem;
}

.buttonNormalSize {
  min-height: 40px;
  font-size: $text_sm;
  line-height: 1.25rem;
  padding: 0.625rem 1rem;
}

.buttonLargeSize {
  min-height: 44px;
  font-size: $text_tiny;
  line-height: 1.5rem;
  padding: 0.625rem 1.125rem;
}

.primaryButton {
  background-color: $green_yellow_500;
  border: 1px solid $green_yellow_500;
  color: $main_black_900;

  &:hover {
    background-color: $green_yellow_600;
  }

  &:active,
  &:focus {
    box-shadow: 0px 0px 0px 4px $violet_25;
    border: 1px solid $green_yellow_100;
  }

  &:disabled {
    cursor: initial;
    font-weight: 500;
    background-color: $black_700;
    border: 1px solid $black_500;
    color: $black_500;

    &:hover {
      box-shadow: 0px 0px 0px;
    }
  }
}

.secondaryButton {
  background-color: $black_500;
  border: 1px solid $black_500;
  color: $black_0;

  &:hover {
    background-color: $black_70;
  }

  &:active,
  &:focus {
    box-shadow: 0px 0px 0px 4px $black_500, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:disabled {
    cursor: initial;
    color: #5e5e5e;
    background-color: $black_500;
  }
}

.tertiaryButton {
  color: #c8fd6a;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    color: $green_yellow_600;
    text-decoration: underline;
  }

  &:disabled {
    cursor: initial;
    text-decoration: none;
    color: $black_50;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;

  svg {
    align-self: normal;
  }
}

.iconReverseContainer {
  flex-direction: row-reverse;
}

.spacer {
  width: 0.6rem;
}
