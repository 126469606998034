@import 'src/assets/scss/customVariables';

.feature {
  border: 1px solid $black_40;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 6rem;
  padding: $spacer;
  cursor: pointer;
  min-width: 7.5rem;
  color: $black_0;

  &:hover {
    border: 1px solid $green_yellow_500;
    box-shadow: none;
  }

  .text {
    font-size: $text_tiny;
  }
}

.active {
  color: $black_500;
  border-radius: 0.5rem;
  border: 1px solid $green_yellow_500;
  background: linear-gradient(93deg, #B8EDE0 -3.48%, #C8FD6A 115.52%);
}
