@import 'src/assets/scss/customVariables';

.modal {
  .body {
    padding-top: $spacer4;
    position: relative;
    height: 40rem;

    .loadingWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      z-index: 5000;
    }

    .workingSection {
      display: flex;
      width: 100%;
      height: 35rem;

      .start {
        width: 15rem;
        margin-right: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &Items {
          max-height: 16rem;
          overflow-y: auto;

          .item {
            cursor: pointer;
            margin-bottom: 0.5rem;
            padding: 0.35rem 0.75rem;
            height: 2.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 0.125rem;
            display: flex;
            align-items: center;

            &Active {
              cursor: default;
              background: $black_500;
              color: $black_0;

              .deleteItem {
                * {
                  fill: $black_0;
                }
              }
            }

            &Text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 20rem;
            }

            .deleteItem {
              display: none;
              cursor: pointer;
            }

            &:hover {
              .deleteItem {
                display: block;
              }
            }
          }
        }
      }

      .end {
        width: 49rem;

        .formItem {
          display: flex;
          margin-bottom: $spacer;

          &Label {
            width: 12rem;
            font-size: $text_tiny;
            font-weight: 700;
          }

          &Type {
            display: flex;
            column-gap: 1rem;
            width: 100%;
          }
        }

        .maxFiles {
          margin-bottom: $spacer;
        }

        .sameRatio {
          margin-bottom: $spacer;
          color: $red_300;
          display: flex;
          align-items: center;

          &Text {
            margin-left: 0.5rem;
          }
        }

        .mainSection {
          display: flex;

          .carouselMediasWrapper {
            max-height: 24rem;
            overflow-y: auto;
            display: flex;

            $height: 9rem;
            $marginBottom: $spacer;

            .slides {
              width: 4rem;
              margin-right: 1rem;

              .slide {
                font-size: $text_tiny;
                height: $height;
                display: flex;
                align-items: center;
                margin-top: 1rem;
              }

              .separator {
                margin-bottom: $marginBottom;
              }
            }

            .medias {
              width: 100%;
              height: 100%;

              .media {
                height: 9rem;
                border-radius: 8px;
                border: 1px solid $black_40;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: $spacer;

                .drag {
                  height: 100%;
                  min-width: 3rem;
                  background: $black_500;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 0.5rem;
                  cursor: move;
                }

                .image {
                  width: 7rem;
                  height: 7rem;

                  video {
                    width: inherit;
                    height: inherit;
                  }
                }

                .details {
                  padding: $spacer 0 $spacer $spacer;
                  width: 40rem;

                  .actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: $spacer;

                    .size {
                      color: $black_100;
                      font-size: $text_sm;
                    }

                    .delete {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      svg {
                        width: 1rem;
                        height: 1rem;
                        opacity: 0.5;

                        path {
                          fill: $black_0;
                        }

                        &:hover {
                          opacity: 1;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }

                .detailsWithDrag {
                  padding-left: 1rem;
                  width: 32rem;
                }
              }

              .dropSection {
                height: 1rem;
              }

              .separator {
                margin-bottom: $marginBottom;
              }
            }
          }

          .multiSizeMediasWrapper {
            max-height: 24rem;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;

            .medias {
              width: 100%;
              height: 100%;
              gap: 1.5rem 2rem;
              display: flex;
              align-items: flex-start;
              align-content: flex-start;
              flex-wrap: wrap;
              flex-shrink: 0;

              .media {
                border-radius: 8px;
                border: 1px solid $black_40;
                padding: $spacer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                gap: 0.75rem;

                &Header {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 100%;

                  .sizeSelector {
                    cursor: pointer;

                    > span {
                      margin-right: 0.5rem;
                    }
                  }

                  .delete {
                    width: 1rem;
                    height: 1rem;
                    cursor: pointer;
                  }
                }

                // do not remove this inheritance, otherwise ImageKit transformation doesn't work, actual width is set via style
                .video {
                  video {
                    width: inherit;
                    height: inherit;
                  }
                }

                &Content {
                }
              }

              .mediaSameRation {
                border: 1px solid $red_300 !important;
              }
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: $spacer;

      .button {
        margin-left: $spacer;
      }
    }
  }
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: $spacer;
  padding: $spacer3;
  border: 1px dashed $black_60;
  border-radius: 10px;
  background: $black_500;
  outline: none;
  transition: border 0.24s ease-in-out;
  font-size: 0.875rem;
  height: 24rem;
  width: 100%;

  &WithMedias {
    @extend .dropzone;
    width: 13rem;
    margin-bottom: 0;
    height: 15rem;
  }

  .upload {
    font-size: $text_tiny;
    margin-bottom: $spacer2;
  }

  .dragAndDrop {
    font-size: $text_sm;
    display: flex;

    .browse {
      margin-left: $spacer1;
      min-height: 0.5rem;
      font-size: $text_sm;
    }

    .text1 {
      color: $black_100;
    }
  }

  .text2 {
    color: $black_100;
  }
}
