@import 'src/assets/scss/customVariables';

.container {
  cursor: pointer;
  position: relative;

  @media only screen and (min-width: 442px) {
    width: 442px;
    height: 44px;
  }
}

.multiSelectDropDownWrapper {
  padding: 0.625rem 0.875rem;
  font-size: $text_tiny;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $black_40;
  border-radius: 0.5rem;
  box-sizing: border-box;

  &:hover {
    border: 1px solid $black_40;
  }

  &:active,
  &:focus {
    box-shadow: 0px 0px 0px 4px #adadad, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.multiSelectDropDownWrapperDarkMode {
  background-color: $black_500;
  border: 1px solid $black_500;
  color: $black_0;

  &:active,
  &:focus {
    border: 1px solid $black_500;
    box-shadow: none;
    outline: none;
  }

  .placeholder {
    opacity: 1;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  width: 97%;

  p {
    // width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.3rem;
  }

  .searchInput {
    background-color: $black_500;
    border: 0;
    box-shadow: none;
    color: $black_0;
    outline: none;
    padding-top: 4px;
    padding-left: 0;
    width: 20%;
    font-size: $text_tiny;
  }

  div {
    min-width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: $green_yellow_500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $text_xs;
    color: $main_black_900;
    margin-right: 14px;
    margin-left: auto;
  }
}

.downIcon {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill: $main_black_900;
    }
  }
}

.downIconDarkMode {
  svg {
    path {
      fill: $black_0;
    }
  }
}

.focused {
  border: 1px solid $black_40;
  outline: none;
}

.iconContainer {
  top: 15px;
  right: 13px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.menu {
  position: absolute;
  background-color: $black_0;
  z-index: 2;

  @media only screen and (min-width: 442px) {
    width: 442px;
  }
}

.menuDarkMode {
  position: absolute;
  background-color: $black_700;
  z-index: 2;

  @media only screen and (min-width: 442px) {
    width: 442px;
  }
}
