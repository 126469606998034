@import 'src/assets/scss/customVariables';

.container {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main_black_900;
  //background-image: url("/assets/img/publicBackground.png");
  //background-repeat: no-repeat;
  //background-size: cover;

  .logo {
    position: absolute;
    top: 2rem;
    left: 24px;

    img {
      height: 3rem;
    }
  }

  .contentContainer {
    min-width: 404px;

    .titleContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 16px;

      .title {
        color: $black_0;
      }

      .subtitle {
        color: $black_0;
        max-width: 320px;
        text-align: center;
      }
    }
  }
}
