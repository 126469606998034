@import 'src/assets/scss/customVariables';

.container {
  min-height: 125px;
  //@media only screen and (min-width: 442px) {
  //  width: 442px;
  //}
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    margin-top: 8px;
    color: $black_100;
  }
}

.textareaWrapper {
  font-family: 'Poppins', sans-serif;
  border: 1px solid $black_100;
  -webkit-appearance: none;
  padding: 12px;
  font-size: $text_tiny;
  border-radius: 4px;
  display: flex;
  height: 116px;
  width: -webkit-fill-available;
  resize: vertical;
  background-color: $black_500;
  color: $black_0;

  &::placeholder {
    color: $black_60;
  }

  &:hover {
    border: 1px solid $black_40;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:focus {
    border: 1px solid $black_40;
    box-shadow: 0px 0px 0px 4px #adadad, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    outline: none;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
