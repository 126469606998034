@import 'src/assets/scss/customVariables';

.selectorInputWrapper {
  color: $main_black_900;
}

.dropdownInputWrapper {
  height: fit-content;
  max-height: 20rem;
}

.wrapper {
  padding: $spacer * 6 $spacer7 $spacer6;
  display: flex;
  gap: 1rem;
  overflow-y: hidden;
  height: calc(100vh - 8rem);

  .sidebar {
    width: 15rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &Header {
      color: $black_0;
      font-size: $text_sm;
      margin-left: -1rem;
    }

    &Item {
      cursor: pointer;
      font-size: $text_s;
      color: $black_0;
    }

    &ItemActive {
      cursor: initial;
      background: $black_500;
      font-weight: 500;
    }
  }

  .contentWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow: hidden;

    .back {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: $text_sm;
      color: $black_0;
      cursor: pointer;
      width: fit-content;

      &:hover {
        text-decoration: underline;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $black_0;

      &Start {
        font-weight: 600;
        font-size: $text_xl;
      }

      &End {
        display: flex;
        align-items: center;
        gap: 1rem;

        &Label {
          font-weight: 600;
          font-size: $text_sm;
        }

        .templateName {
          color: $black_0;
          cursor: pointer;
          width: 20rem;
        }

        &Share {
          height: 1.1rem;
          border-radius: 0.5rem;
          padding: 12px 14px 12px 14px;
          border: 1px solid transparent;
          background-color: $black_500;
          display: flex;
          align-items: center;
          gap: 9px;
          font-size: $text_sm;
          color: $black_0;
          cursor: pointer;

          &:hover {
            border: 1px solid $violet_100;
          }
        }
      }
    }

    .content {
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .card {
        box-sizing: border-box;
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        flex-shrink: 0;
        border-radius: 0.5rem;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        background-color: $black_700;
        color: $black_0;

        &Title {
          font-size: $text_s;
          font-weight: 600;
        }

        &Subtitle {
          font-size: $text_sm;
          color: #6d6d6d;
        }
      }

      .chartCards {
        display: grid;
        flex-wrap: wrap;
        gap: 1.5rem;
        grid-template-columns: 1fr 1fr;

        .chartCard {
          height: 26rem;
          gap: 0;
        }
      }

      .firstRow {
        display: flex;
        gap: 1.5rem;

        .creatives {
          height: 9rem;
          width: 15rem;

          &Number {
            color: $violet_400;
            font-size: $text_xxl;
            font-weight: 600;
          }
        }

        .report {
          height: 9rem;
          flex: 1;

          &Details {
            display: flex;
            font-size: $text_sm;
            white-space: nowrap;
            flex-wrap: wrap;
          }
        }
      }

      .top50Creatives {
        &Grid {
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
          align-items: center;

          &Item {
            width: 5rem;
          }
        }
      }

      .sectionName {
        font-weight: 600;
        font-size: $text_s;
      }

      .summary {
        background-color: $green_yellow_500;
        padding: 0px 16px 0px 16px;
      }

      .richEditorCard {
        @extend .card;
        height: 25rem;
        position: relative;

        .richEditor {
          height: 17rem;
          border: 1px solid $black_100;
          border-radius: 8px;
          padding: $spacer2;
          width: 100%;

          &Toolbar {
            border: none;
          }

          &Editor {
            max-height: 10.5rem;
          }
        }

        .save {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
        }

        .saveWithoutSubtitle {
          position: absolute;
          top: 0.8rem;
          right: 1.5rem;
        }
      }

      .richEditorCardReadonly {
        @extend .card;
        height: 17rem;
        position: relative;

        .richEditor {
          height: 16rem;
          border: 1px solid $black_100;
          border-radius: 8px;
          padding: $spacer2;
          width: 100%;

          &Toolbar {
            border: none;
          }

          &Editor {
            max-height: 8.5rem;
          }
        }
      }
    }
  }
}
