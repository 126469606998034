@import 'src/assets/scss/customVariables';

body {
  overflow-x: hidden;
}

.suspense {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: $bp_lg) {
  body {
    // 'overflow-y: hidden' disables vertical mobile & tablet scrolling
    overflow-y: auto;
  }
}
