@import 'src/assets/scss/customVariables';

.modalContainer {
  div {
    padding: 0px;
  }
  .modalHeader {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: $text_base;
    line-height: 28px;
    h4 {
      margin: 0;
    }

    .closeIconContainer {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
  .modalBody {
    padding: 0 24px;
  }
  .modalFooter {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
    }
  }
}
