@import 'src/assets/scss/customVariables';

.container {
  display: flex;
  &_color {
    width: 7px;
  }

  &_info {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 16px 18px;

    &_title {
      font-size: 14px;
      font-weight: 700;
      color: $black_0;
      margin-bottom: 8px;
    }

    &_description {
      font-size: 14px;
      font-weight: 400;
      color: $black_0;
    }
  }
}
