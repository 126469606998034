@import 'src/assets/scss/customVariables';

.main {
  width: 100%;

  .container {
    position: relative;
    height: $inputHeight;

    .inputWrapper {
      -webkit-appearance: none;
      padding: 0.625rem 0.875rem;
      font-size: $text_tiny;
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      border: 1px solid transparent;
      border-radius: 0.5rem;
      box-sizing: border-box;
      background: $black_500;
      color: $black_0;

      &::placeholder {
        color: $black_60;
      }

      &:hover {
        border: 1px solid $black_40;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      &:active,
      &:focus {
        border: 1px solid $black_40;
        box-shadow: 0px 0px 0px 4px #adadad, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        outline: none;
      }
    }

    .inputError {
      border: 1px solid $red_300;
    }

    .withIconLeft {
      padding-left: 3rem;
    }

    .withIconRight {
      padding-right: 3rem;
    }

    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      top: 0.65rem;
    }

    .iconLeft {
      left: 1rem;
    }

    .iconRight {
      right: 1rem;
    }
  }

  .errorMessage {
    display: flex;
    align-items: center;
    color: $red_300;
    margin-top: 5px;

    svg {
      margin-right: $spacer1;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
